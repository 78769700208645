import React from 'react'
import { FaAward, FaChartLine, FaHandshake, FaTools } from 'react-icons/fa'
import { MdBuild } from 'react-icons/md'

const WhyChooseUs = () => {
  return (
    <section className='services container' id='why-choose-us'>
      <h2>Why Choose Us</h2>
      <div className='service-cards'>
        <div className='service-card'>
          <FaAward className='icon' />
          <h3>Proven Expertise</h3>
          <p>Our team consists of seasoned marketing professionals with a track record of success across various industries.</p>
        </div>
        <div className='service-card'>
          <MdBuild className='icon' />
          <h3>Customized Solutions</h3>
          <p>We understand that every business is unique, which is why we tailor our services to meet your specific needs and objectives.</p>
        </div>
        <div className='service-card'>
          <FaChartLine className='icon' />
          <h3>Data-Driven Approach</h3>
          <p>We rely on data and analytics to inform our strategies, ensuring that your marketing efforts are both effective and efficient.</p>
        </div>
        <div className='service-card'>
          <FaTools className='icon' />
          <h3>Cutting-Edge Tools</h3>
          <p>We leverage the latest marketing technologies to stay ahead of industry trends and deliver innovative solutions.</p>
        </div>
        <div className='service-card'>
          <FaHandshake className='icon' />
          <h3>Client-Centric Philosophy</h3>
          <p>Your success is our success. We prioritize building long-term relationships and delivering results that exceed expectations.</p>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs