import React from 'react';
import { LuMail } from "react-icons/lu";
import { SlLocationPin } from "react-icons/sl";


const ContactUs = () => {
  const handleSubmit = e => {
    e.preventDefault();
    console.log('Form submitted');
  };

  return (
    <section className='contact-us container' id='contact'>
      <div className='left'>
        <h2>Contact Us</h2>
        <p>You can contact us by mail. Or you can leave your contact details and we will contact you</p>
        <div className='block'>
          <LuMail className='icon' />
          <a className='mail' href='mailto:bandgconsulting1@gmail.com'>
          bandgconsulting1@gmail.com
          </a>
        </div>
        <div className='block'>
          <SlLocationPin className='icon' />
          <p className='address'>
          AI Shmookh Business Center М 1003, One UAQ, UAQ Free Trade Zone, Umm AI Quwain, U.A.E
          </p>
        </div>
      </div>
      <div className='right'>
        <h2>Send a message</h2>
        <div className='contact-form-container'>
          <form className='contact-form' onSubmit={handleSubmit}>
            <div className='form-group'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='name' required />
            </div>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' name='email' required />
            </div>
            <div className='form-group'>
              <label htmlFor='message'>Message</label>
              <textarea id='message' name='message' rows='5' required></textarea>
            </div>
            <button type='submit' className='submit-button' value='send'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
