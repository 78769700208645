import React from 'react';
import { MdOutlineBusinessCenter, MdOutlineCampaign } from 'react-icons/md';
import { FaPaintBrush, FaChalkboardTeacher } from 'react-icons/fa';
import { BiBarChart } from 'react-icons/bi';

const Services = () => {
  return (
    <section className='services container' id='services'>
      <h2>Our Services</h2>
      <div className='service-cards'>
        <div className='service-card'>
          <MdOutlineBusinessCenter className='icon' />
          <h3>Marketing Strategy Development</h3>
          <ul>
            <li>Crafting comprehensive marketing plans that align with your business goals.</li>
            <li>Identifying target audiences and positioning strategies to maximize reach and engagement.</li>
          </ul>
        </div>
        <div className='service-card'>
          <MdOutlineCampaign className='icon' />
          <h3>Digital Marketing</h3>
          <ul>
            <li><bold>SEO & SEM:</bold> Boosting online visibility through search engine optimization and targeted advertising.</li>
            <li><bold>Content Marketing:</bold> Creating compelling content that resonates with your audience and drives conversions.</li>
            <li><bold>Social Media Management:</bold> Building and managing social media campaigns to engage and grow your online community.</li>
          </ul>
        </div>
        <div className='service-card'>
          <FaPaintBrush className='icon' />
          <h3>Branding & Identity</h3>
          <ul>
            <li><bold>Brand Development:</bold> Defining and refining your brand’s voice, image, and values.</li>
            <li><bold>Visual Identity Design:</bold> Designing logos, websites, and other brand elements that reflect your company’s essence.</li>
          </ul>
        </div>
        <div className='service-card'>
          <BiBarChart className='icon' />
          <h3>Market Research & Analytics</h3>
          <ul>
            <li>Conducting in-depth market research to inform your marketing strategies.</li>
            <li>Analyzing campaign performance and customer behavior to optimize future initiatives.</li>
          </ul>
        </div>
        <div className='service-card'>
          <FaChalkboardTeacher className='icon' />
          <h3>Consulting & Training</h3>
          <ul>
            <li>Providing one-on-one consulting to address specific marketing challenges.</li>
            <li>Offering workshops and training sessions to upskill your in-house marketing team.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Services;