import React from 'react'
import avt01 from '../assets/avt01.png'
import avt02 from '../assets/avt02.png'

const testimonialsData = [
  {
    name:"Jane S.",
    title: "CEO of XYZ Tech",
    img: avt01,
    comment: "Working with B and G International has transformed our marketing efforts. Their strategic approach and deep understanding of our industry have helped us achieve a significant increase in brand awareness and lead generation."
  },{
    name: "Mark T.", 
    title: "Founder of Green Ventures", 
    img: avt02,
    comment: "The team at B and G International truly listens to our needs and crafts solutions that are both creative and effective. Their insights have been invaluable in helping us navigate the competitive market landscape."
  },{
    name: "Sarah L.", 
    title: "Marketing Director at Bright Apparel", 
    img: avt01,
    comment: "Thanks to B and G International, we’ve seen a dramatic improvement in our online presence. Their expertise in digital marketing has driven measurable growth in our sales and customer engagement."
  }
]

const Testimonials = () => {
  return (
    <div className="testimonials" id="testimonials">
      <h2 className="main-title">Testimonials</h2>
      <div className="container">
        {testimonialsData.map(item=>(
          <div className="box" key={item.name}>
            <div className="image">
              <img src={item.img} alt="avatar" />
            </div>
            <h3>{item.name}</h3>
            <span className="title">{item.title}</span>
            <div className="rate">
              <i className="filled fas fa-star"></i>
              <i className="filled fas fa-star"></i>
              <i className="filled fas fa-star"></i>
              <i className="filled fas fa-star"></i>
              <i className="far fa-star"></i>
            </div>
            <p>{item.comment}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Testimonials