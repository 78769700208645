import React from 'react';
import whoWeAre1 from '../assets/who-we-are-01.jpg';

const WhoWeAre = () => {
  return (
    <section className='who-we-are container' id='about'>
      <div className='who-we-are-content'>
        <h2>Who We Are</h2>
        <p>Our dedication lies on helping companies in navigating the constantly changing world of marketing. Our goal, as a group of seasoned business professionals, is to enable organizations of all sizes to realize their greatest potential by utilizing creative and data-driven marketing techniques. We blend creativity, analytics, and technology to promote growth and improve brand visibility, all while keeping an eye on measurable results.</p>
        <p>Because we use a client-centric approach, we customize our services to each business's specific requirements. Assisting existing businesses seeking to grow or startups seeking to make a big impression on the market, <bold>B and G International</bold> is your go-to partner for long-term success.</p>
        <button className='get-started-button'>Get Started</button>
      </div>
      <div className='who-we-are-images'>
        <img src={whoWeAre1} alt='Team working' className='who-we-are-image' />
      </div>
    </section>
  );
};

export default WhoWeAre;
