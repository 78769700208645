import React from 'react';
import hero1 from '../assets/hero-01.jpg';
import { FaQuoteLeft } from 'react-icons/fa';

const Hero = () => {
  return (
    <section className='hero container' id='home'>
      <div className='hero-content'>
        <h1>B and G International Consulting FZE</h1>
        <p>
          We help companies navigate the ever-changing marketing landscape. Our seasoned professionals enable businesses of all sizes to reach their full potential through innovative, data-driven marketing strategies.
        </p>
        <a href='#services' className='get-started-button'>Explore Our Services</a>
      </div>
      <div className='hero-image'>
        <img src={hero1} alt='Marketing Solutions' />
        <div className='hero-image-text'>
          <FaQuoteLeft className='icon' />
          <p>
            Blending creativity, analytics, and technology to drive growth and brand visibility.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
