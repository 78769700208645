import React from 'react';
import { CiFacebook, CiTwitter, CiLinkedin } from 'react-icons/ci';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content container'>
        <div className='footer-section'>
          <h3>About Us</h3>
          <p>
            B and G International Consulting FZE specializes in IT marketing services and consulting, providing innovative solutions to drive business success.
          </p>
          <br/>
          <p>License: 9983 dated 30.11.2023</p>
          <p>Director: PITTARELLO GIULIA</p>
        </div>
        <div className='footer-section'>
          <h3>Contact</h3>
          <p>Address: AI Shmookh Business Center М 1003, One UAQ, UAQ Free Trade Zone, Umm AI Quwain, U.A.E</p>
          <p>Email: bandgconsulting1@gmail.com</p>
        </div>
        <div className='footer-section'>
          <h3>Follow Us</h3>
          <div className='social-icons'>
            <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
              <CiFacebook />
            </a>
            <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
              <CiTwitter />
            </a>
            <a href='https://linkedin.com' target='_blank' rel='noopener noreferrer'>
              <CiLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <p>&copy; 2024 B and G International Consulting FZE. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
